import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { CircleColor } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import CasinoLoyaltyProgramProgress from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramProgress/CasinoLoyaltyProgramProgress.vue';
import CasinoLoyaltyProgramList from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramList/CasinoLoyaltyProgramList.vue';
const bemClass = 'casino_loyalty_program_next_level_info';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramNextLevelInfo',
    props: {
        levelTitle: {
            default: ''
        },
        progress: {},
        currentPoints: {},
        remainingPoints: {},
        levelDataList: {}
    },
    setup (__props) {
        const circleColor = CircleColor.PURPLE;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style[bemClass])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style[`${bemClass}__title`])
                }, _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_NEXT_LEVEL')), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style[`${bemClass}__wrapper`])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style[`${bemClass}__content`])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style[`${bemClass}__left`])
                        }, [
                            _createVNode(CasinoLoyaltyProgramProgress, _mergeProps(_ctx.progress, {
                                "is-gradient": false,
                                "circle-color": _unref(circleColor)
                            }), null, 16, [
                                "circle-color"
                            ]),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style[`${bemClass}__level-title`])
                            }, _toDisplayString(_ctx.levelTitle), 3)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style[`${bemClass}__right`])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style[`${bemClass}__current`])
                            }, _toDisplayString(_ctx.currentPoints), 3),
                            _cache[0] || (_cache[0] = _createTextVNode(" / ")),
                            _createElementVNode("span", null, _toDisplayString(_ctx.remainingPoints), 1)
                        ], 2)
                    ], 2),
                    _createVNode(CasinoLoyaltyProgramList, _mergeProps(_ctx.levelDataList, {
                        "is-dark": ""
                    }), null, 16)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramNextLevelInfo'
                ]
            ]);
        };
    }
});

import { CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';
export default function getBalanceProps(status, requiredPoints, currentPoints, $t, formatMoney) {
    const requiredPointsFormatted = formatMoney(requiredPoints || 0, {
        currency: 'L'
    });
    const currentPointsFormatted = formatMoney(currentPoints || 0, {
        hideCurrency: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    let text = '';
    text = status === CasinoLoyaltyProgramLevelStatus.PROCESS || status === CasinoLoyaltyProgramLevelStatus.LOCK ? `${currentPointsFormatted} / ${requiredPointsFormatted}` : $t('WEB2_LOYALTY_PROGRAM_LEVEL_FINISHED').value;
    return {
        text
    };
}

import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useCasinoLoyaltyProgramDaysCounter } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramDaysCounter',
    props: {
        locale: {
            default: 'ru_RU'
        }
    },
    emits: [
        "click-handler"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { fullPhrase, clickHandler } = useCasinoLoyaltyProgramDaysCounter(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VBadge, {
                label: _unref(fullPhrase),
                onClick: _unref(clickHandler)
            }, null, 8, [
                "label",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramDaysCounter'
                ]
            ]);
        };
    }
});

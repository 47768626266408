import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { CasinoIconName, IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { ListType } from 'web/src/components/List/enums';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
export default function useCasinoLoyaltyProgramList(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const router = useRouter();
    const { navigateToGamePageById } = useEgsNavigation(router);
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const list = computed(()=>{
        const result = [];
        if (props.multiplier) result.push({
            icon: CasinoIconName.CASINO_ROULETTE,
            label: $translate('WEB2_LOYALTY_PROGRAM_MULTIPLIER_TEXT').value,
            value: `${props.multiplier}x`
        });
        if (props.bonusAmount) result.push({
            icon: IconName.COIN_USD,
            label: $translate('WEB2_LOYALTY_PROGRAM_BONUS_FOR_ACCOUNT_TEXT').value,
            value: $formatMoney(props.bonusAmount)
        });
        if (props.freespin) {
            const buttonLabel = $translate('WEB2_LOYALTY_PROGRAM_BONUS_OPEN_BTN').value;
            result.push({
                icon: CasinoIconName.CASINO_SEVENS,
                label: $translate('WEB2_BONUSES_FREESPIN').value,
                value: `${props.freespin} ${$translate('WEB2_BONUSES_FREESPIN_COUNT').value}`,
                to: RouteName.BONUSES,
                buttonLabel: props.freespinAvailable && props.freespinGameId ? buttonLabel : '',
                badgeLabel: props.freespinSpent ? $translate('WEB2_LOYALTY_PROGRAM_BONUS_RECEIVED').value : ''
            });
        }
        if (props.bonusPointsCount) result.push({
            icon: IconName.LEONS,
            label: $translate('WEB2_LOYALTY_PROGRAM_BONUS_POINTS_TEXT').value,
            value: $formatMoney(props.bonusPointsCount, {
                currency: 'L'
            })
        });
        return result;
    });
    const listType = computed(()=>{
        if (list.value.length > 1) return ListType.GROUP;
        return ListType.SEPARATE;
    });
    const clickEvent = (to)=>{
        if (to) router.push({
            name: to
        });
    };
    return {
        list,
        clickEvent,
        listType
    };
}

import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import BaseRouteName from '@leon-hub/routing-config-names/src/BaseRouteName';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import { useCasinoLoyaltyProgramList } from './composables';
import CasinoLoyaltyProgramGiftItem from '../CasinoLoyaltyProgramGiftItem/CasinoLoyaltyProgramGiftItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramList',
    props: {
        bonusAmount: {},
        multiplier: {},
        freespin: {},
        bonusPointsCount: {},
        status: {},
        freespinAvailable: {
            type: Boolean
        },
        freespinSpent: {
            type: Boolean
        },
        freespinGameId: {},
        freespinProvider: {},
        isDark: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { list, clickEvent, listType } = useCasinoLoyaltyProgramList(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-loyalty-program-list']]: true,
                    [_ctx.$style['casino-loyalty-program-list--is-dark']]: _ctx.isDark
                })
            }, [
                (_openBlock(), _createBlock(VList, {
                    key: 1,
                    "list-type": _unref(listType)
                }, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, index)=>(_openBlock(), _createBlock(VListItem, {
                                    key: index,
                                    title: item.label,
                                    "icon-name": item.icon,
                                    "suffix-text": item.value,
                                    "button-label": item.buttonLabel,
                                    "badge-label": item.badgeLabel,
                                    "no-hover": "",
                                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-list__item']),
                                    onClickButton: ($event)=>_unref(clickEvent)(item.to ? item.to : _unref(BaseRouteName).LOYALTY_PROGRAM)
                                }, null, 8, [
                                    "title",
                                    "icon-name",
                                    "suffix-text",
                                    "button-label",
                                    "badge-label",
                                    "class",
                                    "onClickButton"
                                ]))), 128))
                        ]),
                    _: 1
                }, 8, [
                    "list-type"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramList'
                ]
            ]);
        };
    }
});

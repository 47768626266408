import { computed, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';
let CasinoLoyaltyProgramPagePrefetch = class CasinoLoyaltyProgramPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const onboarding = useOnboarding();
        const casinoLoyaltyStore = useCasinoLoyaltyStore();
        const hasOnboarding = computed(()=>!!onboarding.getOnboardingById(OnboardingIdsEnum.EGS_LOYALTY_PROGRAM));
        const currentLevelId = toRef(()=>casinoLoyaltyStore.currentLevelId);
        const hideCasinoLoyaltyOnboardingPage = toRef(()=>casinoLoyaltyStore.hideCasinoLoyaltyOnboardingPage);
        if (currentLevelId.value > 0 && !hideCasinoLoyaltyOnboardingPage.value && hasOnboarding) {
            casinoLoyaltyStore.setHideCasinoLoyaltyOnBoardingPageState(true);
            next({
                name: RouteName.ONBOARDING,
                params: {
                    id: OnboardingIdsEnum.EGS_LOYALTY_PROGRAM
                }
            });
            return Promise.resolve();
        }
        next();
        return Promise.resolve();
    }
};
export { CasinoLoyaltyProgramPagePrefetch as default };

import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
export default function useCasinoLoyaltyProgramDaysCounter(props, emits) {
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const shortLocale = computed(()=>props.locale.split('_')[0]);
    const dayCounterLabel = computed(()=>{
        const date = new Date();
        const lastDayInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        return lastDayInMonth - date.getDate();
    });
    const dayCounter = computed(()=>({
            count: String(dayCounterLabel.value)
        }));
    const remainingText = computed(()=>LanguageMicroSettings.plural([
            $translate('WEB2_LOYALTY_PROGRAM_REMAINING_ONE', dayCounter).value,
            $translate('WEB2_LOYALTY_PROGRAM_REMAINING', dayCounter).value,
            $translate('WEB2_LOYALTY_PROGRAM_REMAINING', dayCounter).value
        ], dayCounterLabel.value, props.locale));
    const counterText = computed(()=>LanguageMicroSettings.plural([
            $translate('WEB2_LOYALTY_PROGRAM_REMAINING_DAYS_ONE', dayCounter).value,
            $translate('WEB2_LOYALTY_PROGRAM_REMAINING_DAYS_TWO', dayCounter).value,
            $translate('WEB2_LOYALTY_PROGRAM_REMAINING_DAYS_MANY', dayCounter).value
        ], dayCounterLabel.value, props.locale));
    const fullPhrase = computed(()=>'ru' === shortLocale.value ? `${remainingText.value} ${dayCounterLabel.value} ${counterText.value}` : `${dayCounterLabel.value} ${counterText.value} ${remainingText.value}`);
    const firstDayOfNextMonth = computed(()=>{
        const date = new Date();
        const firstDay = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
        const customerLocale = props.locale.replace('_', '-');
        return firstDay.toLocaleDateString(customerLocale, {
            month: 'long',
            day: 'numeric'
        });
    });
    const firstDayOfNextMonthParam = computed(()=>({
            firstDayOfNextMonth: firstDayOfNextMonth.value
        }));
    const confirmMessageText = $translate('WEB2_LOYALTY_PROGRAM_MODAL_REMAINING_TEXT', firstDayOfNextMonthParam);
    function emitClick() {
        emits('click-handler', true);
    }
    function clickHandler() {
        showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                iconName: IconName.DATE_RANGE,
                iconKind: JumbotronIconKind.DEFAULT,
                fullHeight: false,
                title: fullPhrase.value,
                confirmMessage: confirmMessageText.value,
                buttons: [
                    {
                        label: $translate('WEB2_LOYALTY_PROGRAM_MODAL_REMAINING_BUTTON_TEXT').value
                    }
                ],
                dataTestId: 'loyalty-program-remaining'
            }
        });
        emitClick();
    }
    return {
        fullPhrase,
        clickHandler
    };
}

import { ModalWidth } from 'web/src/components/Modal/enums';
export default function getModalProps(customBackground) {
    return {
        width: ModalWidth.DEFAULT,
        isCentered: false,
        isOverlayCloseAllowed: false,
        isFullHeight: true,
        isFullHeightCentered: false,
        isOverlayVisible: false,
        customBackground: customBackground
    };
}

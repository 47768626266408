import { defineComponent as _defineComponent } from 'vue';
import { mergeProps as _mergeProps, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LoyaltyProgramTopBarRouteComponent',
    props: {
        title: {}
    },
    setup (__props) {
        const infoButtonProperties = {
            kind: ButtonKind.NAV,
            iconName: IconName.INFO_OUTLINE,
            iconSize: IconSize.SIZE_24
        };
        const router = useRouter();
        function onClick() {
            useAnalytics().push(AnalyticsEvent.CLICK_MAP, {
                clickCounter: {
                    loyaltyProgram: 'info'
                }
            });
            router.push({
                name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_INFO
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _ctx.title
            }, _createSlots({
                _: 2
            }, [
                {
                    name: "prefix",
                    fn: _withCtx(()=>[
                            _createVNode(VButton, _mergeProps(infoButtonProperties, {
                                onClick: onClick
                            }), null, 16)
                        ]),
                    key: "0"
                }
            ]), 1032, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'LoyaltyProgramTopBarRouteComponent'
                ]
            ]);
        };
    }
});

import { computed } from 'vue';
import { CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';
export default function getDescriptionText(name, selectedLevel, selectedLevelStatus, isLastLevel, $t, formatMoney) {
    const baseParams = computed(()=>({
            bonusValue: formatMoney(selectedLevel?.bonusAmount || 0),
            multiplier: `${selectedLevel?.multiplier}x`,
            freespinValue: `${selectedLevel?.freespinCount}`
        }));
    if (selectedLevelStatus === CasinoLoyaltyProgramLevelStatus.SUCCESS) {
        if (isLastLevel) return $t('WEB2_LOYALTY_PROGRAM_COMPLETED_FINAL_LEVEL_DESCR', baseParams).value;
        return $t('WEB2_LOYALTY_PROGRAM_COMPLETED_LEVEL_DESCR', baseParams).value;
    }
    const params = computed(()=>({
            ...baseParams.value,
            pointsValue: formatMoney(selectedLevel?.requiredPoints || 0, {
                hideCurrency: true
            }),
            levelEGS: selectedLevel?.name || name
        }));
    if (selectedLevelStatus === CasinoLoyaltyProgramLevelStatus.PROCESS) return $t('WEB2_LOYALTY_PROGRAM_NOT_COMPLETED_LEVEL_DESCR', params).value;
    return $t('WEB2_LOYALTY_PROGRAM_LOCKED_LEVEL_DESCR', params).value;
}

export default function getProgramListProperties(selectedLevel) {
    return {
        bonusAmount: selectedLevel?.bonusAmount || 0,
        multiplier: selectedLevel?.multiplier || 0,
        freespin: selectedLevel?.freespinCount || 0,
        bonusPointsCount: selectedLevel?.bonusPointsCount || 0,
        status: selectedLevel?.status,
        freespinAvailable: selectedLevel?.freespinAvailable,
        freespinSpent: selectedLevel?.freespinSpent,
        freespinGameId: selectedLevel?.freespinGameId || void 0,
        freespinProvider: selectedLevel?.freespinProvider || void 0
    };
}

import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "casino-loyalty-program-title"
};
const _hoisted_2 = {
    class: "casino-loyalty-program-title__top"
};
const _hoisted_3 = {
    key: 0,
    class: "casino-loyalty-program-title__leon-container"
};
const _hoisted_4 = {
    class: "casino-loyalty-program-title__leon"
};
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramTitle',
    props: {
        label: {},
        leon: {},
        status: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _ctx.label ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _ctx.status !== _unref(CasinoLoyaltyProgramLevelStatus).PROCESS ? (_openBlock(), _createBlock(_unref(VIcon), {
                        key: 0,
                        class: _normalizeClass([
                            "casino-loyalty-program-title__icon",
                            `casino-loyalty-program-title--${_ctx.status}`
                        ]),
                        name: _ctx.status === _unref(CasinoLoyaltyProgramLevelStatus).SUCCESS ? _unref(IconName).CHECK_FILLED : _unref(IconName).LOCK
                    }, null, 8, [
                        "class",
                        "name"
                    ])) : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
                ]),
                _ctx.leon ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.leon), 1)
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramTitle'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});

import { onMounted, computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { CasinoLoyaltyProgramIconSizes, CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';
import { ModalSelector } from 'web/src/modules/core/enums';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getRemainingPoints, getCurrentPointsToString, getProgressProps, isLastLevel, levelSettingsById } from 'web/src/modules/casino-loyalty-program/store/utils';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';
import { getBalanceProps, getIconProps, getTitleProps, getModalProps, getHeaderProperties, getProgramListProperties, getDescriptionText } from './utils';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useCasinoLoyaltyProgramRoutePage(props) {
    const locale = toRef(useI18nStore(), 'locale');
    const formatMoney = useFormatMoney();
    const router = useRouter();
    const { $translate } = useI18n();
    const analytics = useAnalytics();
    const casinoLoyaltyStore = useCasinoLoyaltyStore();
    const modalSelector = ModalSelector.DESKTOP_INNER_MODAL;
    const casinoLoyaltyProgramInfoRouteName = CasinoRouteName.CASINO_LOYALTY_PROGRAM_INFO;
    const selectedId = ref(0);
    const currentPoints = computed(()=>casinoLoyaltyStore.currentPoints);
    const name = computed(()=>casinoLoyaltyStore.name);
    const currentLevelCardProperties = computed(()=>casinoLoyaltyStore.currentLevelCardProperties);
    const currentLevelId = computed(()=>casinoLoyaltyStore.currentLevelId);
    const levelSettingsWithStatus = computed(()=>casinoLoyaltyStore.levelSettingsWithStatus);
    const nextLevelData = computed(()=>casinoLoyaltyStore.nextLevelData);
    const nextLevelId = computed(()=>casinoLoyaltyStore.nextLevelData?.id);
    const level = computed(()=>levelSettingsById(selectedId.value, levelSettingsWithStatus.value));
    const levelSettings = computed(()=>casinoLoyaltyStore.levelSettings);
    const modalProps = computed(()=>getModalProps(level.value?.settingsBackground));
    const iconProps = computed(()=>getIconProps(level.value?.imageUrl, level.value?.status));
    const titleProps = computed(()=>getTitleProps(level.value?.name, level.value?.status));
    const balanceProps = computed(()=>getBalanceProps(level.value?.status, level.value?.requiredPoints, currentPoints.value, $translate, formatMoney));
    const headerProperties = computed(()=>getHeaderProperties($translate));
    const remainingPoints = computed(()=>getRemainingPoints(nextLevelData.value?.requiredPoints, formatMoney));
    const currentPointsToString = computed(()=>getCurrentPointsToString(currentPoints.value, formatMoney));
    const progressProps = (id)=>{
        const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);
        return getProgressProps(selectedLevel?.progress, selectedLevel?.imageUrl, selectedLevel?.status);
    };
    const programListProperties = (id)=>{
        const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);
        return getProgramListProperties(selectedLevel);
    };
    const descriptionText = (id)=>{
        const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);
        const selectedLevelStatus = selectedLevel?.status;
        const isLastLevelValue = isLastLevel(id, levelSettings.value);
        return getDescriptionText(name.value, selectedLevel, selectedLevelStatus, isLastLevelValue, $translate, formatMoney);
    };
    const progressText = (id)=>{
        const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);
        const selectedLevelRequiredPoints = selectedLevel?.requiredPoints;
        const isLevelInProgress = selectedLevel?.status === CasinoLoyaltyProgramLevelStatus.PROCESS;
        const levelPoints = isLevelInProgress ? currentPoints.value : selectedLevelRequiredPoints;
        return isLevelInProgress ? `${levelPoints} / ${selectedLevelRequiredPoints} XP` : `${selectedLevelRequiredPoints} XP`;
    };
    const modalIconSize = computed(()=>level.value?.progress && level.value?.progress <= 100 ? CasinoLoyaltyProgramIconSizes.SIZE_160 : CasinoLoyaltyProgramIconSizes.SIZE_180);
    const progress = computed(()=>level.value?.progress || 0);
    const onClose = ()=>{
        selectedId.value = 0;
        router.replace({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM
        });
    };
    const onCloseAll = ()=>{
        router.closeModal();
    };
    const showLevelDescription = (id)=>{
        selectedId.value = id;
        router.push({
            name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_ID,
            params: {
                id: String(id)
            }
        });
    };
    const clickCounter = ()=>{
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                loyaltyProgram: 'nDaysLeft'
            }
        });
    };
    onMounted(async ()=>{
        await casinoLoyaltyStore.fetchCasinoLoyaltyStatus();
        selectedId.value = parseInt(props.id || '0', 10);
    });
    return {
        selectedId,
        clickCounter,
        showLevelDescription,
        currentPointsToString,
        remainingPoints,
        progressProps,
        nextLevelId,
        programListProperties,
        currentPoints,
        casinoLoyaltyProgramInfoRouteName,
        modalSelector,
        modalProps,
        onClose,
        headerProperties,
        onCloseAll,
        iconProps,
        modalIconSize,
        titleProps,
        balanceProps,
        descriptionText,
        progressText,
        locale,
        currentLevelCardProperties,
        currentLevelId,
        levelSettingsWithStatus,
        progress
    };
}

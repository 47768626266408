import { computed, toRef } from 'vue';
import { lobbyTypeLoyaltyProgram } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
export default function useCasinoLoyaltyLobby() {
    const lobbyType = lobbyTypeLoyaltyProgram;
    const siteConfigStore = useSiteConfigStore();
    const analytics = useAnalytics();
    const egsSkeletons = toRef(siteConfigStore, 'egsSkeletons');
    const skeletons = computed(()=>egsSkeletons.value?.loyalty ?? []);
    function gameClick() {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                loyaltyProgram: 'openGame'
            }
        });
    }
    return {
        lobbyType,
        skeletons,
        gameClick
    };
}

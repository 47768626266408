import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useCasinoLoyaltyLobby } from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyLobby/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyLobby',
    setup (__props) {
        const { lobbyType, skeletons, gameClick } = useCasinoLoyaltyLobby();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(LobbyBlock), {
                class: "casino-loyalty-lobby",
                "test-el": "casino-loyalty-lobby",
                "lobby-type": _unref(lobbyType),
                "is-modal": "",
                "no-background": "",
                "is-header-with-horizontal-margin": "",
                skeletons: _unref(skeletons),
                onGameClick: _unref(gameClick)
            }, null, 8, [
                "lobby-type",
                "skeletons",
                "onGameClick"
            ])), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyLobby'
                ]
            ]);
        };
    }
});
